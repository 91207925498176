@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  pre {
    white-space: pre-wrap;
  }

  * {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }
  .navbar-menu {
    @apply text-gray-500 font-medium;
  }

  .testmonial-p {
    @apply text-2xl lg:text-4xl text-gray-500 font-bold;
  }

  .container1 {
    @apply bg-gray-100 rounded-xl;
  }

  .heroBg {
    background-image: url(assets/img/hero.png)
  }

  .benifitbg {
    background-image: url(assets/img/benifitbg.png)
  }

  .title {
    @apply w-full text-xl lg:text-6xl font-bold text-gray-800 pb-0 lg:pb-2
  }

  .subtitle {
    @apply text-gray-500 text-sm lg:text-lg font-medium max-w-sm py-2 lg:py-5
  }

  .description {
    @apply w-full text-blue-500 text-base lg:text-lg pb-2 lg:pb-5 font-medium
  }

  .circle-arrow {
    @apply h-[50px] w-[40px] cursor-pointer
  }

  .circle-fill {
    @apply w-[20px] h-[20px] rounded-full
  }

  .circle-icon {
    @apply border-2 border-gray-800 w-[40px] h-[40px] rounded-full items-center justify-center pt-1;
  }

  .circle-icon .footer-icon {
    @apply text-white text-2xl m-auto;
  }

  .about-container {
    @apply flex flex-col lg:flex-row px-5 lg:px-16 pt-14 lg:pt-28 items-center;
  }

  .about-container .about-img {
    @apply bg-gray-200 w-full lg:w-1/2;
  }

  .about-container .about-div {
    @apply flex flex-col items-center w-full lg:w-1/2
  }

  .about-container .about-map {
    @apply flex flex-row gap-4 lg:gap-24 pt-5 lg:pt-16
  }

  .marquee-wrapper {
    text-align: center;
  }

  .marquee-wrapper .marquee-container {
    overflow: hidden;
  }

  .marquee-inner span {
    @apply float-left w-1/2
  }

  .marquee-wrapper .marquee-block {
    @apply flex items-center justify-center text-center relative h-[120px] lg:h-[160px] lg:w-[calc(270px_*_6)] w-[calc(220px_*_6)];;
    overflow: hidden;
    box-sizing: border-box;
  }

  .marquee-inner {
    @apply block w-[200%] absolute items-center;
  }

  .marquee-inner.to-left {
    animation: marqueeLeft 25s linear infinite;
  }

  .marquee-inner.to-right {
    animation: marqueeRight 25s linear infinite;
  }

  .marquee-item {
    @apply inline-flex bg-gray-800 rounded-lg items-center justify-center text-center w-[200px] h-[100px] lg:w-[250px] lg:h-[130px];
    margin: 0 10px;
    transition: all .2s ease-out;
  }

  @keyframes marqueeLeft {
    0% {
      left: 0;
    }

    100% {
      left: -100%;
    }
  }

  @keyframes marqueeRight {
    0% {
      left: -100%;
    }

    100% {
      left: 0;
    }
  }
}